import React, { useEffect, useRef, useState } from 'react'

import {
  LeftPageLinksRenderer,
  LoginButtonRenderer,
  LogoRenderer,
  Navbar,
  NavbarMobile,
  RightPageLinksRenderer,
  SearchRenderer
} from '.'

import { RoundedButtonBlock } from 'Blocks'
import Sticky from 'Helpers/stickyHeader'
import TimesSVG from 'SVGs/times.svg'
import './HeaderBlock.css'

const HeaderBlock = ({ data, inEditMode, isAnnouncementHidden, isSecondaryAnnouncementHidden }) => {
  if (!data) {
    if (!inEditMode) return null
    return <header>Empty Header. You can edit it in root page.</header>
  }

  const [isAnnouncementVisible, setIsAnnouncementVisible] = useState(!isAnnouncementHidden)
  const [isSecondaryAnnouncementVisible, setIsSecondaryAnnouncementVisible] = useState(!isSecondaryAnnouncementHidden)

  const headerRef = useRef(null)
  const secondAnnouncementRef = useRef(null)
  const desktopNavStickyReference = useRef(null)
  const mobileNavStickyReference = useRef(null)

  const mobileNavRef = useRef(null)
  const { LeftPageLinks, RightPageLinks, LogoImage, SearchPage, ApplyButton, LoginButton, LoginForm, Announcement, SecondAnnouncement, NavbarLinkCollection } = data
  const NavbarMobileRenderer = () => <NavbarMobile mobileNavRef={mobileNavRef} NavbarLinks={NavbarLinkCollection} LogoImage={LogoImage} inEditMode={inEditMode} SearchPage={SearchPage} LoginButton={LoginButton} ApplyButton={ApplyButton} LoginForm={LoginForm} LeftPageLinks={LeftPageLinks} RightPageLinks={RightPageLinks} />


  const hideAnnouncement = () => {
    setIsAnnouncementVisible(false)
    setSessionCookie('announcementHidden', true)
  }

  const hideSecondaryAnnouncement = () => {
    setIsSecondaryAnnouncementVisible(false)
    setSessionCookie('secondaryAnnouncementHidden', true)
  }

  // The expiration date is set to 8 hours from the current time
  // These cookies are read by the server (BasePageController.cs) to determine if the announcement should be shown
  function setSessionCookie(cookieName, cookieValue) {
    var date = new Date(); // Create a new date object
    date.setTime(date.getTime() + (8 * 60 * 60 * 1000)); // Set the time to 8 hours from now
    var expires = "expires=" + date.toUTCString(); // Set the expiration date to 8 hours from now
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
  }

  useEffect(() => {
    mobileNavStickyReference.current = new Sticky('#mobileNavigation', { stickyClass: 'is-sticky', wrap: true, wrapWith: '<div></div>', stickyForMin: 10, stickyForMax: 1023 })
    desktopNavStickyReference.current = new Sticky('#desktopNavigation', { stickyClass: 'is-sticky', wrap: true, wrapWith: '<div></div>', stickyForMin: 1023 })

    // this ResizeObserver is used to update the sticky navs dimensions when the height of the entire header changes
    // this is a catch-all solution for when the header height changes due to the announcements becoming hidden or when navigating between pages and the height of the header changes between the pages
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        mobileNavStickyReference.current.update()
        desktopNavStickyReference.current.update()
      }
    })

    if (headerRef.current) {
      resizeObserver.observe(headerRef.current)
    }

    return () => {
      if (headerRef.current) {
        resizeObserver.unobserve(headerRef.current)
      }
      resizeObserver.disconnect()
    }
  }, [])

  const Layout = () => {
    return (
      <div className="xl:py-7 lg:py-6 md:py-5 py-3
                      xl:px-20 lg:px-10 md:px-6 sm:px-4 px-2
                      font-body">
        <div className="hidden lg:flex justify-between xl:text-base text-sm font-medium">
          <div className="grid gap-y-2">
            <LeftPageLinksRenderer data={LeftPageLinks} name="LeftPageLinks" inEditMode={inEditMode} />
            <div className="2xl:hidden lg:block hidden">
              <RightPageLinksRenderer data={RightPageLinks} name="RightPageLinks" inEditMode={inEditMode} />
            </div>
          </div>
          <div className="leading-10 flex items-center">
            <div className="2xl:block hidden">
              <RightPageLinksRenderer data={RightPageLinks} name="RightPageLinks" inEditMode={inEditMode} />
            </div>
            <span className="inline-block xl:pl-8 lg:pl-5 md:pl-5 pl-3">
              <SearchRenderer data={SearchPage} name="SearchPage" inEditMode={inEditMode} />
            </span>
            <span className="inline-block xl:pl-8 lg:pl-5 md:pl-5 pl-3">
              <RoundedButtonBlock data={ApplyButton} name="ApplyButton" inEditMode={inEditMode} />
            </span>
            <span className="inline-block xl:pl-8 lg:pl-5 md:pl-5 pl-3">
              <LoginButtonRenderer data={LoginButton} name="LoginButton" form={LoginForm} formName="LoginForm" inEditMode={inEditMode} />
            </span>
          </div>
        </div>
        <div className="mt-6 hidden lg:inline-block">
          <LogoRenderer data={LogoImage} name="LogoImage" inEditMode={inEditMode} />
        </div>
        <NavbarMobileRenderer />
      </div>
    )
  }

  return (
    <header className="select-none" ref={headerRef}>

      <div>
        {(isSecondaryAnnouncementVisible && SecondAnnouncement || inEditMode) &&
          <div className="item second-header" >
            <div ref={secondAnnouncementRef} className={'bg-red-600 flex py-4 px-2 sm:px-4 lg:px-10 xl:px-20'} data-epi-edit="SecondAnnouncement">
              <div className="container mx-auto max-w-screen-xl prose-sm lg:prose Announcement" dangerouslySetInnerHTML={{ __html: SecondAnnouncement }} />
              <div className='self-center text-white hover:cursor-pointer' onClick={() => hideSecondaryAnnouncement()}><TimesSVG className='w-[25px] h-[25px]' /></div>
            </div>
          </div>
        }
      </div>

      <div>
        {(isAnnouncementVisible && Announcement || inEditMode) &&
          <div className='bg-fuchsia flex py-4 px-2 sm:px-4 lg:px-10 xl:px-20'>
            <div className="container mx-auto max-w-screen-xl prose-sm lg:prose Announcement" dangerouslySetInnerHTML={{ __html: Announcement }} />
            <div className='self-center text-white hover:cursor-pointer' onClick={() => hideAnnouncement()}><TimesSVG className='w-[25px] h-[25px]' /></div>
          </div>
        }
      </div>

      <div id='mobileNavigation' className="container mx-auto max-w-screen-2xl bg-white z-50" ref={mobileNavRef}>
        <Layout />
      </div>

      <Navbar data={NavbarLinkCollection} name="NavbarLinkCollection" inEditMode={inEditMode} />

      {inEditMode && (
        <div className="xl:px-20 lg:px-10 md:px-6 sm:px-4 px-2
                        text-sm text-gray-500 pt-10">
          <div className="inline-block float-right" data-epi-edit="LoginForm">Change Login Form</div>
        </div>
      )}
    </header>
  )
}

export default React.memo(HeaderBlock)
