import React, { useState } from 'react'

import RatesCardBlock from './RatesCardBlock'
import { contentAreaDataMapper } from 'Helpers/transform'
import { addWebPToImageURL } from 'Helpers/utilities'

const RatesBlock = ({ data, inEditMode }) => {
  const { Heading, MainContentArea, BackgroundImage } = data
  const blockData = contentAreaDataMapper(MainContentArea)
  const [active, setActive] = useState(1)

  return (
    <div className="container mx-auto max-w-screen-xl bg-top bg-no-repeat bg-contain" style={{ backgroundImage: `url(${addWebPToImageURL(BackgroundImage?.Url)})` }}>
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      lg:py-16 md:py-10 py-6">
        {(Heading || inEditMode) && (<div className="grid justify-items-center">
          <h2 className="lg:text-4xl md:text-2xl text-xl font-medium font-display">
            <span data-epi-edit="Heading">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </span>
          </h2>
        </div>)}
        <div data-epi-edit="MainContentArea" className="md:py-12 py-4 grid grid-cols-3 gap-4 md:gap-6 items-end">
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <div data-epi-block-id={Id} key={i} onClick={() => i !== active && setActive(i)}>
                <RatesCardBlock data={blockData[Id]} inEditMode={inEditMode} isActive={i === active} />
              </div>
            )
          })}
        </div>
      </div>
      {inEditMode && (
        <div className="my-8">
          <span className="text-gray-500" data-epi-edit="BackgroundImage">Change container background image</span>
        </div>
      )}
    </div>
  )
}

export default React.memo(RatesBlock)
