import React from 'react'
import './EmbedFormBlock.css'

const EmbedFormBlock = ({data, givenCss}) => {
    let {EmbedCode} = data;
    let cssClasses = "embedFormBlock";

    if (givenCss !== null && givenCss !== '')
    {
        cssClasses += " " + givenCss
    }

    if (EmbedCode === undefined) {
        EmbedCode = data;
    }

    return (
        <>
            <div className={cssClasses} dangerouslySetInnerHTML={{ __html: EmbedCode }}></div>
        </>
    )
};

export default React.memo(EmbedFormBlock)
