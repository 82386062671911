import React from 'react'

const PromotionCarouselCardBlock = ({ data, inEditMode, isActive }) => {
  const { Image, Text } = data
  if (!Text && !Image && !inEditMode) return null

  return (
    <div className={`justify-items-center max-w-xs ${isActive ? 'border-violet shadow-active' : ''}
      border border-white cursor-pointer m-2 rounded-lg lg:h-44 md:h-32 h-24 overflow-hidden bg-cover md:w-auto w-32`} style={{ backgroundImage: `url(${Image?.Url})` }}>
      <div className="hidden md:block font-body lg:text-xl text-white p-4">{isActive && Text}</div>
      <div className="md:hidden font-body text-xs whitespace-pre-wrap text-white px-2 py-3">{isActive && Text}</div>
    </div>
  )
}

export default React.memo(PromotionCarouselCardBlock)
