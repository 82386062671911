import React from 'react'

import { RouterLinkTemplate } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const HeroBannerSlideWithProductCardsBlock = ({ data, inEditMode }) => {
    const { BackgroundImage, TextColor, HeroHeading, HeroSubheading, ContentTextAlignment, HeroImage, ContentArea } = data
    const textAlignment = ContentTextAlignment === 'left' ? 'text-left' : ContentTextAlignment === 'center' ? 'text-center' : 'text-right';

    return (
        <div className='relative bg-no-repeat bg-cover bg-center h-full' data-bg={`${addWebPToImageURL(BackgroundImage?.Url)}`} style={{ backgroundImage: `url(${addWebPToImageURL(BackgroundImage?.Url)})` }}>
            <div className={`flex gap-2 lg:gap-20 items-center md:justify-center h-full mx-6 md:mx-12 ${TextColor}`}>

                <div className='max-w-sm md:max-w-md lg:max-w-xl'>
                    <div className={`text-xl md:text-4xl lg:text-6xl font-medium font-display py-2 lg:py-6 ${textAlignment}`}>
                        <h1>{HeroHeading}</h1>
                    </div>
                    <div className={`lg:text-lg md:text-base text-sm md:font-medium md:font-display font-body pb-2 ${textAlignment}`}>
                        <h4>{HeroSubheading}</h4>
                    </div>
                    <div data-epi-edit="ContentArea" className={`swiper-no-swiping flex gap-4 overflow-x-scroll hidden-scrollbars whitespace-nowrap py-4`}>
                        {ContentArea && ContentArea.length > 0 && ContentArea.map((block, i) => {
                            const { ContentLink } = block
                            const { Id } = ContentLink
                            return (
                                <a href={block?.Url} className='block w-full' data-epi-block-id={Id} key={i}>
                                    <div className={`flex flex-col justify-center items-center gap-2 h-full
                                            p-2 md:p-4 lg:p-4 max-w-xs
                                            border border-gray-100 cursor-pointer duration-200 hover:border-violet-bright
                                            shadow hover:shadow-active rounded-lg transform transition-all`}>
                                        <img data-epi-edit="Image" src={addWebPToImageURL(block?.Image?.Url)} data-src={addWebPToImageURL(block?.Image?.Url)} alt={block?.Image?.AltTag} width={'60'} height={'60'} />
                                        <div data-epi-edit="Text" className="font-display font-medium text-sm lg:text-xl self-center text-center whitespace-normal">
                                            {block?.Text}
                                            {!block?.Text && inEditMode && 'Click to edit'}
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                </div>

                <div className={`hidden md:block w-full lg:w-max ${!HeroImage?.Url ? 'md:invisible' : ''}`}>
                    <img src={addWebPToImageURL(HeroImage?.Url)} data-src={addWebPToImageURL(HeroImage?.Url)} alt={HeroImage?.AltTag} className="mx-auto object-cover" width={300} height={300} data-epi-edit="HeroImage" />
                </div>
            </div>
        </div>
    )
}

export default React.memo(HeroBannerSlideWithProductCardsBlock)
