import React, { useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import VideoModal from './VideoModal'
import VideoCardBlockLgSVG from './VideoCardBlockLg.svg'
import VideoCardBlockWideSVG from './VideoCardBlockWide.svg'

const getCardAndImageClassName = (size) => {
  let cardSize, imageSize, textPadding, headingFont
  switch (size) {
    case 'lg':
      cardSize = 'lg:max-w-xl md:max-w-lg w-full'
      imageSize = 'aspect-w-5 aspect-h-4'
      textPadding = 'lg:px-10 p-4'
      headingFont = 'font-display font-medium tracking-tight lg:text-2xl text-sm'
      break
    case 'wide':
      cardSize = 'md:max-w-full w-full'
      imageSize = 'aspect-w-16 aspect-h-9'
      textPadding = 'lg:px-6 md:py-3 p-4'
      headingFont = 'font-display font-medium tracking-tight lg:text-lg text-sm'
      break
    case 'sm':
      cardSize = 'max-w-xs xl:w-96 lg:w-72 md:w-52'
      imageSize = 'aspect-w-3 aspect-h-2'
      textPadding = 'lg:px-6 md:py-3 p-4'
      headingFont = 'font-display font-medium tracking-tight lg:text-2xl text-sm'
      break
    default:
      cardSize = 'md:max-w-md w-full'
      imageSize = 'aspect-w-16 aspect-h-10'
      textPadding = 'lg:px-10 lg:py-5 p-4'
      headingFont = 'font-display font-medium tracking-tight lg:text-2xl text-sm'
      break
  }

  return [cardSize, imageSize, textPadding, headingFont]
}

const VideoCardBlock = ({ data, name, inEditMode, size }) => {
  const { Text, Image, VideoLink, Content } = data

  if (!Text && !Image && !inEditMode) return null

  const [isOpen, setIsOpen] = useState(false)
  const [cardSize, imageSize, textPadding, headingFont] = getCardAndImageClassName(size)
  const svgString = encodeURIComponent(size === 'wide' ? renderToStaticMarkup(<VideoCardBlockWideSVG />) : renderToStaticMarkup(<VideoCardBlockLgSVG />))

  const ContentContainer = () => {
    return (
      <div className={`grid ${cardSize} overflow-hidden mx-auto bg-white shadow
        ${VideoLink ? 'cursor-pointer hover:shadow-active' : 'cursor-default'} duration-200 rounded-lg transform transition-all`}>
        <div data-epi-edit="Image" className="w-full overflow-hidden">
          {Image && (
            <div className={`w-full ${imageSize} relative bg-center bg-no-repeat bg-cover`} style={{ backgroundImage: `url(${Image?.Url})` }} >
              {VideoLink && <div className="absolute bg-center bg-no-repeat bg-cover top-0 right-0 bottom-0 left-0" style={{ backgroundImage: `url("data:image/svg+xml,${svgString}")` }} />}
            </div>
          )}
          {inEditMode && !Image && !size && 'Click to edit Image'}
        </div>
        <div className={textPadding}>
          <h5 data-epi-edit="Text" className={headingFont}>{Text}</h5>
          {Content && (
            <p className="font-body lg:text-base text-sm py-3 hidden sm:block">{Content}</p>
          )}
        </div>
      </div>
    )
  }

  if (inEditMode) {
    if (size) return <ContentContainer />
    return (
      <>
        <ContentContainer />
        <div data-epi-edit="VideoLink" className="ml-5 inline-block text-sm text-gray-500">Edit video link</div>
      </>
    )
  }

  return (
    <>
      <div onClick={() => VideoLink && setIsOpen(true)}><ContentContainer editable={false} /></div>
      {VideoLink && <VideoModal isOpen={isOpen} data={VideoLink} onClose={() => setIsOpen(false)} />}
    </>
  )
}

export default React.memo(VideoCardBlock)
