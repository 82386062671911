import React, { useContext, useEffect, useRef } from 'react'

import pages from './pages'
import api from 'Helpers/api'
import { getComponentType } from 'Helpers/transform'
import { useLocationPathChange, useQueryParams } from 'Hooks'
import { AppContext } from './contexts'

function PageComponentSelector({ inEditMode, onPageChanged }) {
  const { appContext, setAppContext } = useContext(AppContext)
  const { data } = appContext
  const componentType = getComponentType(data, pages)
  const DynamicComponent = pages[componentType || 'Page']
  const queryParams = useQueryParams()

  let path
  useLocationPathChange((pathname) => {
    path = pathname
    setAppContext({ ...appContext, isLoading: true })
    api.getContentByFriendlyUrl(pathname, queryParams)
      .then(res => {

        setAppContext({
          ...appContext,
          data: res,
          isLoading: false,
          header: res.Header ? res.Header : appContext.defaultHeader, // WELLBYCSO-77 - if the page has an overridden header or footer, use that instead of the default
          footer: res.Footer ? res.Footer : appContext.defaultFooter
        })

        if (typeof appInsights !== 'undefined' && document !== 'undefined') {
          // eslint-disable-next-line no-undef
          appInsights.trackPageView(res.MetaTitle || res.Name)
        }

        onPageChanged();
      })
      .catch(err => console.log(err))
  })

  return (
    <div className={`main-container transition duration-500 ${appContext.isLoading ? 'opacity-0 animate-fade-out-down' : 'opacity-1 animate-fade-in-up'}`}>
      <DynamicComponent key={path} data={data} inEditMode={inEditMode} />
    </div>
  )
}

export default PageComponentSelector
