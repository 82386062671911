import React from 'react'

import { Markdown } from 'Components'

const RawContentBlock = ({ data, inEditMode }) => {
  const { Content } = data

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      lg:pt-12 lg:pb-8 md:py-7 py-4">
        <Markdown data={Content} name='Content' inEditMode={inEditMode} />
      </div>
    </div>
  )
}

export default React.memo(RawContentBlock)
