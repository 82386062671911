import React from 'react'
import './Markdown.css'

const Markdown = ({ data, name, inEditMode, className }) => {
  if (!data && inEditMode) {
    return (
      <div className={`text-sm text-gray-500 p left box-sizing right box-sizing padding-y-1 p img @media ${className}`}>
        <span data-epi-edit={name}>Click to edit {name}</span>
      </div>
    )
  }

  const markdown = { __html: data }
  return <div className={`text-left lg:prose prose-sm inline-block ${className}`} data-epi-edit={name} dangerouslySetInnerHTML={markdown} />
}

export default React.memo(Markdown)
