import React, { useState } from 'react'

const CarouselMobileRenderer = ({ data, name, inEditMode }) => {
  if (!data || data.length === 0) {
    if (!inEditMode) return null
    return <div data-epi-edit={name}>Click to add images</div>
  }

  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <>
      {data && data.length > 0 && data.map((block, i) => {
        const { Quote, Name } = block
        const fullYear = (new Date(block.Date)).getFullYear()
        return (
          <div className={`lg:hidden text-center transition ${i === currentIndex ? 'animate-fade-in' : 'hidden'}`} key={i}>
            <h4 className="font-display md:text-lg text-base italic">“{Quote}”</h4>
            <h5 className="font-display md:text-base text-xs md:py-4 py-2 italic">{Name} {fullYear}</h5>
          </div>
        )
      })}
      <div data-epi-edit={name} className="lg:hidden justify-around overflow-x-scroll hidden-scrollbars whitespace-nowrap py-2">
        {data && data.length > 0 && data.map((block, i) => {
          const { ContentLink, Image } = block
          const { Id } = ContentLink
          return (
            <div data-epi-block-id={Id} key={i} className="inline-block" onClick={() => setCurrentIndex(i)}>
              <div className={`justify-items-center max-w-xs ${i === currentIndex ? 'border-violet shadow-active' : ''}
                border border-white cursor-pointer m-2 rounded-lg lg:h-44 md:h-32 h-24 md:w-40 w-32 overflow-hidden bg-cover`} style={{ backgroundImage: `url(${Image?.Url})` }} />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default React.memo(CarouselMobileRenderer)
