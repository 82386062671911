import React, { useEffect } from 'react'

import ContentArea from 'Components/ContentArea'
import { AuthorProfile } from 'Blocks/Blog'
import { getDateWithFormat } from 'Helpers/utilities'
import { BlockCard } from 'Blocks/BlogSearchWithRelatedArticlesCardBlock'
import FaceBookShare from 'SVGs/facebook.svg'
import TwitterShare from 'SVGs/twitter.svg'
import LinkedInShare from 'SVGs/linkedIn.svg'
import EmailInShare from 'SVGs/email.svg'
import EmbedFormBlock from '../blocks/Forms/EmbedFormBlock/EmbedFormBlock'
import { addWebPToImageURL } from 'Helpers/utilities'

const BlogArticlePage = ({ data, inEditMode }) => {
  const { MetaTitle, MetaDescription, FeaturedImage, Heading, StartPublish, MainContentArea, Category, Author, RelatedArticles } = data

  const openTwitterShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?text=${MetaTitle} ${window.location.href}`,
      'popUp1',
      'width=600,height=600,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
    )
  }
  const openFacebookShare = () => {
    window.open(
      `http://www.facebook.com/sharer.php?s=100&p[title]=${MetaTitle}&p[url]=${window.location.href}&p[summary]=${MetaDescription}`,
      'popUp1',
      'width=600,height=600,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
    )
  }
  const openLinkedInShare = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
      'popUp1',
      'width=600,height=600,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
    )
  }
  const openGmailShare = () => {
    window.open(
      `mailto:?subject=${MetaTitle}&body=${MetaDescription} ${window.location.href}`,
      'popUp1',
      'width=600,height=600,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
    )
  }

  const formEmbedCode = "<script charset=\"utf-8\" type=\"text/javascript\" src=\"//js.hsforms.net/forms/embed/v2.js\"></script>\n<script>\n  hbspt.forms.create({\n    region: \"na1\",\n    portalId: \"21544839\",\n    formId: \"cd6a1d04-4d66-42f8-91eb-f32aa89d71eb\"\n  });\n</script>"

  return (
    <div className="container mx-auto max-w-screen-xl lg:px-12 md:px-8 px-4">
      <div className="-z-10 aspect-w-14 aspect-h-5 mt-6">
        <img data-epi-edit="FeaturedImage" data-src={addWebPToImageURL(FeaturedImage?.Url)} alt={FeaturedImage?.AltTag} width="1100" height="450" className="lazy items-center hidden md:block rounded-md" />
        <img data-epi-edit="FeaturedImage" data-src={addWebPToImageURL(FeaturedImage?.Url)} alt={FeaturedImage?.AltTag} width="768" height="432" className="lazy md:hidden rounded-md" />
      </div>
        <div className="xl:mx-28 lg:mx-24 md:mx-16 xl:-mt-24 lg:-mt-20 md:-mt-12 md:bg-white md:rounded-t-lg md:px-12">
          <h1 className="md:pt-8 pt-4 md:pb-6 lg:text-5xl md:text-3xl text-2xl font-medium font-display tracking-tightest">
            <span data-epi-edit="Heading">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </span>
          </h1>
          <p className="font-display">{getDateWithFormat(new Date(StartPublish))}</p>
          {Author?.PublicName && <p className="font-display font-medium text-lg md:mt-8 mt-5" data-epi-edit="Author">By {Author?.PublicName}</p>}
        </div>
        <div className="md:float-left lg:ml-8 md:ml-4">
          <div className="md:grid md:grid-rows-4 md:mt-0 mt-5">
            <button className="rounded md:mx-0 mr-4 mb-1" onClick={() => openFacebookShare()}>
              <FaceBookShare className="w-5 h-5 text-violet"/>
            </button>
            <button onClick={() => openTwitterShare()} >
              <TwitterShare className="w-5 h-5 text-violet" />
            </button>
            <button className="md:mx-0 mx-4 my-1" onClick={() => openLinkedInShare()} >
              <LinkedInShare className="w-5 h-5 text-violet" />
            </button>
            <button onClick={() => openGmailShare()} >
              <EmailInShare className="w-5 h-5 text-violet" />
            </button>
          </div>
        </div>
        <div className="lg:mx-8 md:mx-14">
          <ContentArea data={MainContentArea} name="MainContentArea" inEditMode={inEditMode} />
        </div>
        {Category?.length > 0 && (
          <div className="xl:mx-20 lg:mx-16 md:mx-8 md:px-12 flex gap-4">
            {Category?.map((item, i) => <div key={i} className="rounded-full text-violet border border-violet px-4 py-1">{item?.Description}</div>)}
          </div>
        )}
        <AuthorProfile data={Author} inEditMode={inEditMode} />
        {RelatedArticles?.length > 0 && <div className="xl:mx-28 lg:mx-20 md:mx-12 md:px-12 mx-8">
          <h2 className="lg:text-4xl text-xl font-medium font-body mb-8">Related articles you might like</h2>
          <div className="flex flex-wrap my-6 -mx-1 lg:-mx-4">
            {RelatedArticles?.slice(0, 3).map((item, i) => {
              return <BlockCard key={i} data={item} inEditMode={inEditMode} readText={'Read'} />
            })}
          </div>
        </div>}
        <div className="md:mb-20 mb-12">
          <EmbedFormBlock data={formEmbedCode} givenCss={"blog mx-auto"}/>
        </div>
    </div>
  )
}

export default React.memo(BlogArticlePage)
